// Include headers 

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './Assets/CSS/index.css';  // Double repetition od 
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Redux/App/store';
import LoadingPage from './Components/LoadingPage/LoadingPage';

// Generation of DOM elements

const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Suspense fallback={<LoadingPage />}>
      <App />
      </Suspense>
    </Provider>
  </React.StrictMode>
);

// This function is fired when the final values for any of the metrics have finished calculating on the page.
//You can use it to log any of the results to the console or send to a particular endpoint.

reportWebVitals();
