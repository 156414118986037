import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import toml from 'toml';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const customBaseQuery = fetchBaseQuery({
  baseUrl: `${API_BASE_URL}/api/v1/optimization`,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token'); 
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('text/plain')) {
      const text = await response.text();
      try {
        return { data: toml.parse(text) };
      } catch (err) {
        return { error: { status: 'PARSING_ERROR', originalStatus: response.status, data: text, error: err.toString() } };
      }
    }
    return response.json();
  },
});

export const optimizationApi = createApi({
  reducerPath: 'optimizationApi',
  baseQuery: customBaseQuery,
  tagTypes: ['Optimization'],
  endpoints: (builder) => ({
    fetchFileSize: builder.query({
      query: ({ userId, projectId }) => `/file-size/${userId}/${projectId}`,
      providesTags: ['Optimization'],
    }),
    fetchCpuCount: builder.query({
      query: ({ userId, projectId }) => `/read-cpu-count/${userId}/${projectId}`,
      providesTags: ['Optimization'],
    }),
    updateCpuCount: builder.mutation({
      query: ({ userId, projectId, cpu_count }) => ({
        url: `/update-cpu-count/${userId}/${projectId}`,
        method: 'PUT',
        body: { cpu_count },
      }),
      invalidatesTags: ['Optimization'],
    }),
    runSimulation: builder.mutation({
      query: ({ userId, projectId }) => ({
        url: `/run/${userId}/${projectId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Optimization'],
    }),
    fetchConfig: builder.query({
      query: ({ userId, projectId }) => `/read-config/${userId}/${projectId}`,
      providesTags: ['Optimization'],
    }),
    updateConfig: builder.mutation({
      query: ({ userId, projectId, config }) => ({
        url: `/update-problem-model/${userId}/${projectId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Problem: config }),
      }),
      invalidatesTags: ['Optimization'],
    }),
    storeConfig: builder.mutation({
      query: ({ userId, projectId }) => ({
        url: `/store-config/${userId}/${projectId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Optimization'],
    }),
    fetchAvailableSurfaces: builder.query({
      query: ({ userId, projectId }) => `/surface/${userId}/${projectId}`,
      providesTags: ['Optimization'],
    }),

    fetchAvailableVolumes: builder.query({
      query: ({ userId, projectId }) => `/volume/${userId}/${projectId}`,
      providesTags: ['Optimization'],
    }),


    updateBoundary: builder.mutation({
      query: ({ userId, projectId, boundaries }) => ({
        url: `/update-boundary/${userId}/${projectId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(boundaries),
      }),
      invalidatesTags: ['Optimization'],
    }),
    updateDomain: builder.mutation({
      query: ({ userId, projectId, domain }) => ({
        url: `/update-domain/${userId}/${projectId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(domain),
      }),
      invalidatesTags: ['Optimization'],
    }),
  }),
});

export const {
  useFetchFileSizeQuery,
  useFetchCpuCountQuery,
  useUpdateCpuCountMutation,
  useRunSimulationMutation,
  useFetchConfigQuery,
  useUpdateConfigMutation,
  useStoreConfigMutation,
  useFetchAvailableSurfacesQuery,
  useFetchAvailableVolumesQuery,
  useUpdateBoundaryMutation,
  useUpdateDomainMutation,
} = optimizationApi;
