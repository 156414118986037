import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${API_BASE_URL}/api/v1/projects`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            //console.log("Token:", token);
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Projects'],
    endpoints: (builder) => ({
        getMyProjects: builder.query({  
            query: (page) => `/get-my-projects?page=${page}&limit=24`,
            providesTags: ['Projects'],
        }),
        getSingleMyProjects: builder.query({
            query: ({ id }) => `/get-single-project/${id}`,
            providesTags: ['Projects'],
        }),
        getProjectStatus: builder.query({
            query: (id) => `/get-project-status/${id}`,
            providesTags: ['Projects'],
        }),
        createProject: builder.mutation({
            query: (data) => ({
                url: `/create-project`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Projects'],
        }),
        updateProject: builder.mutation({
            query: ({ projectName, id }) => ({
                url: `/update-project/${id}`,
                method: 'PATCH',
                body: { projectName },
            }),
            invalidatesTags: ['Projects'],
        }),
        deleteProject: builder.mutation({
            query: ({ id }) => ({
                url: `/delete-project/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Projects'],
        }),
        updateProjectStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/update-project-status/${id}`,
                method: 'POST',
                body: { status },
            }),
            invalidatesTags: ['Projects'],
        }),
    }),
});

export const { 
    useGetMyProjectsQuery, 
    useGetSingleMyProjectsQuery, 
    useGetProjectStatusQuery, 
    useCreateProjectMutation,
    useUpdateProjectMutation, 
    useDeleteProjectMutation,
    useUpdateProjectStatusMutation 
} = projectsApi;
