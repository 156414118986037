import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { projectsApi } from '../Features/projects/projectsApi';
import { quotesApi } from '../Features/quotes/quotesApi';
import AuthSlice from '../Features/User/AuthSlice';
import createQuoteSlice from '../Features/quotes/quotesSlice';
import { userApi } from '../Features/User/userApi';
import configureSlice from '../Features/quotes/configureSlice';
import { materialApi } from '../Features/Material/matarialAPI';
import { feedbackApi } from '../Features/Feedback/feedbackApi';
import stlFileSlice from '../Features/Optimization/stlFileSlice';
import { optimizationApi } from '../Features/Optimization/optimizationApi';

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [quotesApi.reducerPath]: quotesApi.reducer,
    [materialApi.reducerPath]: materialApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [optimizationApi.reducerPath]: optimizationApi.reducer,
    auth: AuthSlice,
    quote: createQuoteSlice,
    configure: configureSlice,
    stlFile: stlFileSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      userApi.middleware,
      projectsApi.middleware,
      quotesApi.middleware,
      materialApi.middleware,
      feedbackApi.middleware,
      optimizationApi.middleware
    ),
});

setupListeners(store.dispatch);
export default store;
