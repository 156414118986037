import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    user: null,
    isLoading: true,
    isSuccess: false,
    isError: false,
    error: '',
}
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const registration = createAsyncThunk(
    "auth/registration",
    async (data) => {
        const response = await axios.post(`${API_BASE_URL}/api/v1/users/registration`, data);
        return response;
    }
)

export const login = createAsyncThunk(
    "auth/login",
    async (data, { dispatch }) => {
        const response = await axios.post(`${API_BASE_URL}/api/v1/users/login`, data);

        // Clear any previous state
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");

        // Set new state
        const user = response?.data?.result?.user;
        const token = response?.data?.result?.token;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        dispatch(setUser(user));
        return response;
    }
)

export const getMe = createAsyncThunk(
    "auth/getMe",
    async () => {
        const token = `Bearer ${localStorage.getItem('token')}`;
        const response = await axios.get(
            `${API_BASE_URL}/api/v1/users/get-me`,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response;
    }
)

export const logout = createAsyncThunk(
    "auth/logout",
    async () => {
        const token = `Bearer ${localStorage.getItem('token')}`;
        const response = await axios.post(
            `${API_BASE_URL}/api/v1/users/logout`,
            {},
            {
                headers: {
                    Authorization: token
                }
            }
        );

        // Clear local storage
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");

        return response;
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        clearState: (state) => {
            state.user = null;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = '';
        },
        loading: (state) => {
            state.isLoading = false;
        },
        loginStatus: (state) => {
            state.isSuccess = false;
            state.error = '';
            state.isError = false;
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registration.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.error = "";
        }).addCase(registration.fulfilled, (state) => {
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        }).addCase(registration.rejected, (state, action) => {
            state.isLoading = false;
            state.user = null;
            state.isError = true;
            state.error = action.error.message;
        }).addCase(login.pending, (state) => {
            state.isLoading = true;
            state.isError = false
            state.error = "";
        }).addCase(login.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = payload?.data?.result?.user;
            localStorage.setItem('user', JSON.stringify(state.user)); 
            state.isError = false;
            state.error = "";
        }).addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.user = null;
            state.isError = true;
            state.error = action.error.message;
        }).addCase(getMe.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.error = "";
        }).addCase(getMe.fulfilled, (state, { payload }) => {
            state.user = payload?.data?.result;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        }).addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.user = null;
        }).addCase(logout.pending, (state) => {
            state.isLoading = true;
        }).addCase(logout.fulfilled, (state) => {
            state.user = null;
            state.isLoading = false;
            state.isSuccess = true;
            window.location.href = "/login";  // Redirect to login page after logout
        }).addCase(logout.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.error.message;
        });
    }
})

export const { setUser, clearState, loading, loginStatus } = authSlice.actions;
export default authSlice.reducer;
