import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stlFile: null,
};

export const stlFileSlice = createSlice({
  name: 'stlFile',
  initialState,
  reducers: {
    setSTLFile: (state, action) => {
      console.log("Setting STL file in Redux store");
      state.stlFile = action.payload;
    },
    clearSTLFile: (state) => {  
      console.log("Clearing STL file from Redux store");
      state.stlFile = null;
    },
  },
});

export const { setSTLFile, clearSTLFile } = stlFileSlice.actions;

export default stlFileSlice.reducer;
