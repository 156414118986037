import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../../Utility/Token/token';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${API_BASE_URL}/api/v1/users` }),
    tagTypes: ['user'],
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            query: () => ({
                url: `/get-all-users`,
                method: 'GET',
                headers: { 'Authorization': `Bearer ${localStorage?.getItem("token")}` },
            }),
            providesTags: ['user']
        }),
        getMe: builder.query({
            query: () => ({
                url: `/get-me`,
                method: 'GET',
                headers: { 'Authorization': `Bearer ${localStorage?.getItem("token")}` },
            }),
            providesTags: ['user']
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: `/update-password`,
                method: 'PATCH',
                headers: { 'Authorization': token },
                body: data
            }),
            invalidatesTags: ['user']
        }),
        changeProfileDetails: builder.mutation({
            query: (data) => ({
                url: `/update-details`,
                method: 'PATCH',
                headers: { 'Authorization': token },
                body: data
            }),
            invalidatesTags: ['user']
        }),
        applyForSupplier: builder.mutation({
            query: (data) => ({
                url: `/applyForSupplier`,
                method: 'POST',
                headers: { 'Authorization': token },
                body: data
            }),
            invalidatesTags: ['user']
        }),
        getApplyForSupplier: builder.query({
            query: () => ({
                url: `/getApplyForSupplier`,
                method: 'GET',
                headers: { 'Authorization': token },
            }),
            providesTags: ['user']
        }),
        makeAddApplyForSupplier: builder.mutation({
            query: (data) => ({
                url: `/makeAddApplyForSupplier`,
                method: 'PATCH',
                headers: { 'Authorization': token },
                body: data
            }),
            invalidatesTags: ['user']
        }),
        deleteApplyForSupplier: builder.mutation({
            query: (data) => ({
                url: `/deleteApplyForSupplier`,
                method: 'DELETE',
                headers: { 'Authorization': token },
                body: data
            }),
            invalidatesTags: ['user']
        }),

        getCredits: builder.query({
            query: (userId) => ({
                url: `/credit/${userId}`,
                method: 'GET',
                headers: { 'Authorization': `Bearer ${localStorage?.getItem("token")}` },
            }),
            providesTags: ['user']
        }),
        updateCredits: builder.mutation({
            query: ({ userId, data }) => ({
                url: `/credit/${userId}`,
                method: 'PATCH',
                headers: { 'Authorization': `Bearer ${localStorage?.getItem("token")}` },
                body: data
            }),
            invalidatesTags: ['user']
        }),
    })
})

export const { useChangePasswordMutation, useGetAllUsersQuery, useGetMeQuery, useChangeProfileDetailsMutation, useApplyForSupplierMutation, useGetApplyForSupplierQuery, useMakeAddApplyForSupplierMutation, useDeleteApplyForSupplierMutation, useGetCreditsQuery, useUpdateCreditsMutation } = userApi;
